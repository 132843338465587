.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay-content {
  background: white;
  padding: 16px;
  border-radius: 16px;
  text-align: center;
  width: 352px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  color: #4D4E50;

  h3 {
    font-family: inter-regular, sans-serif;
    color: #4D4E50;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #4D4E50;

    p {
      font-family: inter-regular, sans-serif;
      margin: 0;
      font-size: 14px;
      font-weight: 400;
    }
  }

  input {
    font-family: inter-regular, sans-serif;
    width: 100%;
    padding: 8px;
    margin-top: 4px;
    border: none;
    font-size: 14px;
    background: #E8E9EB;
    color: #4D4E50;

    &.error-input {
      background: #FFEDED;
      border: 1px solid #FEB2B2;
    }
  }

  button {
    font-size: 16px;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #197B6F;
    color: white;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    &:hover{
      background-color: #1b4332;
    }
  }

  .error {
    border: none;
    color: #DD3F3F;
    font-size: 14px;
    margin-top: 5px;
    text-align: left;
  }
}

